<template>
    <div></div>
</template>

<script>

export default {
    mounted() {
      if (this.$route.query.code) {
        window.opener.postMessage({ type: 'google-auth-code-fetched', code: this.$route.query.code }, '*');
      }
    }
};
</script>
